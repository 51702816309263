import SBox from "components/opinionless/SBox";
// import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from "react";

export const usePrevious = (value, initialValue = null) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      (savedCallback as any).current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useTimer = (numSeconds, onFinished, running = true) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = onFinished;
  }, [onFinished]);

  const [timer, setTimer] = useState(numSeconds);

  const reset = useCallback(() => {
    setTimer(numSeconds);
  }, [numSeconds, setTimer]);

  // Set up the interval.
  useEffect(() => {
    if (!running) {
      return;
    }

    function tick() {
      const newTimer = Math.max(timer - 1, 0);
      setTimer(newTimer);
      if (newTimer === 0 && timer !== 0 && onFinished) {
        onFinished();
      }
    }
    const id = setInterval(tick, 1000);
    return () => clearInterval(id);
  }, [timer, setTimer, onFinished, running]);

  return {
    timer,
    reset,
  };
};

export const useHover = (activeOnStart = false): [boolean, any] => {
  const [isHovering, setIsHovering] = useState(activeOnStart);

  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  return [
    isHovering,
    {
      onMouseEnter,
      onMouseLeave,
    },
  ];
};

// Max time in MS
export const useMouseMoveTimer = (
  maxTime = 3000,
  activeOnStart = true
): [boolean, any] => {
  const [isActive, setIsActive] = useState(activeOnStart);
  // const [lastMouseMoveTime, setLastMouseMoveTime] = useState(null)
  const lastMouseMoveTimeRef = useRef<number | null>(null);

  const onMouseMove = useCallback(
    ({ timeStamp }) => {
      if (
        !lastMouseMoveTimeRef.current ||
        timeStamp - lastMouseMoveTimeRef.current > 1000
      ) {
        setIsActive(true);

        lastMouseMoveTimeRef.current = timeStamp;
        setTimeout(() => {
          if (timeStamp === lastMouseMoveTimeRef.current) {
            setIsActive(false);
          }
        }, maxTime);
      }
    },
    [lastMouseMoveTimeRef, maxTime]
  );

  useEffect(() => {
    if (activeOnStart) {
      onMouseMove({ timeStamp: 0 });
    }
  }, [activeOnStart]);

  return [
    isActive,
    {
      onMouseMove,
    },
  ];
};

export const useFocus = (): [boolean, any] => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const onBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  return [
    isFocused,
    {
      onFocus,
      onBlur,
    },
  ];
};

export const useWindowSize = (): { width: number; height: number } => {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return null;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export const useTraceUpdate = (props) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
};

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return width;
}

export const useInfiniteScroll = (hasMore: boolean, loadNext: () => void) => {
  const sentinelRef = React.useRef<any>(null);
  const [sentinelRefSetTimestamp, setSentinelRefSetTimestamp] = useState<
    number | null
  >(null);
  const sentinelObserver = React.useRef<IntersectionObserver | null>(null);

  const onSentinelIntersection = React.useCallback(
    (entries: any) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          loadNext();
        }
      });
    },
    [hasMore, loadNext]
  );

  const setSentinelRef = useCallback(
    (obj: any) => {
      sentinelRef.current = obj;
      setSentinelRefSetTimestamp(Date.now());
    },
    [setSentinelRefSetTimestamp, sentinelRef]
  );

  useEffect(() => {
    if (sentinelRef.current) {
      sentinelObserver.current = new IntersectionObserver(
        onSentinelIntersection
      );
      sentinelObserver.current.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelObserver.current) {
        sentinelObserver.current.disconnect();
        sentinelObserver.current = null;
      }
    };
  }, [sentinelRefSetTimestamp, onSentinelIntersection]);

  const Sentinel = <SBox ref={setSentinelRef} />;

  return {
    Sentinel,
  };
};

// https://usehooks.com/useDebounce/
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};
// const useDeepCompareMemoize = (value) => {
//     const ref = React.useRef()
//     if (!isEqual(value, ref.current)) {
//         ref.current = value
//     }
//     return ref.current
// }

// export function useDeepMemo<T>(factory: () => T, deps: DependencyList | undefined) {
//     return useMemo<T>(factory, useDeepCompareMemoize(deps))
// }

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
