import React, { useMemo } from 'react'
import { SStyled, useSTheme } from 'styles/theme'
import ClipLoader from 'react-spinners/ClipLoader'

import { FlexProps } from 'rebass'
import SFlex from './SFlex'

interface Props extends FlexProps {
    color?: string
    size?: number
}

const StyledDiv = SStyled.div<{
    color: string
}>`
    background-color: ${(p) => p.color} !important;
`

const SLoadingIndicator: React.FC<Props> = (props: Props) => {
    const theme = useSTheme()
    const { color, size = 30, ...flexProps } = props

    const resolvedColor = useMemo(() => {
        if (color) {
            return theme.colors[color]
        } else {
            return theme.colors.spinner
        }
    }, [color])

    return (
        <SFlex {...flexProps} alignItems={'center'} justifyContent={'center'}>
            <ClipLoader size={size} color={resolvedColor} loading={true} />
        </SFlex>
    )
}

export default SLoadingIndicator
