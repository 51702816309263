import React, { useCallback, useMemo } from 'react'

import { Button, ButtonProps } from 'rebass'
import SText from 'components/opinionless/SText'

import { useSTheme } from 'styles/theme'
import SLoadingIndicator from './SLoadingIndicator'
import { useHover } from '../../utils/customHooks/index'

export enum SButtonType {
    Filled = 'Filled',
    Outline = 'Outline',
    Cancel = 'Cancel',
}

export interface SButtonProps extends ButtonProps {
    loading?: boolean
    buttonType?: SButtonType
    textStyle?: any
}

const SButton: React.FC<SButtonProps> = (props: SButtonProps) => {
    const {
        loading = false,
        buttonType = SButtonType.Filled,
        sx = {},
        textStyle: textStyleProp = {},
        ref,
        children,
        ...otherProps
    } = props
    const theme = useSTheme()

    const [hovering, hoverProps] = useHover()

    const containerStyle = useMemo(() => {
        let bg = 'buttonBg'
        let hoverBg = 'purple350'

        if (buttonType === SButtonType.Filled) {
            bg = 'buttonBg'
            hoverBg = 'purple350'
        } else if (buttonType === SButtonType.Outline) {
            bg = 'purple100'
            hoverBg = 'purple150'
        } else if (buttonType === SButtonType.Cancel) {
            bg = 'gray150'
            hoverBg = 'gray200'
        }

        return {
            bg: hovering ? hoverBg : bg,
        }
    }, [buttonType, hovering])

    const textStyle = useMemo(() => {
        if (buttonType === SButtonType.Filled) {
            return {
                color: 'buttonText',
            }
        } else if (buttonType === SButtonType.Outline) {
            return {
                color: 'purple300',
            }
        } else if (buttonType === SButtonType.Cancel) {
            return {
                color: 'gray400',
            }
        } else {
            return {}
        }
    }, [buttonType])

    const loadingIndicatorColor = useMemo(() => {
        return 'buttonText'
    }, [buttonType])

    const disabledOnClickCallback = useCallback(() => {
        console.log('click')
    }, [])

    return (
        <Button
            sx={{
                ...containerStyle,
                px: 6,
                borderRadius: 1,
                boxShadow: 'main',
                height: '46px',
                cursor: 'pointer',
                border: 'none',
                outline: 'none',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.2s',
                ...sx,
            }}
            disabled={loading}
            {...otherProps}
            {...hoverProps}
        >
            {loading && <SLoadingIndicator size={22} color={loadingIndicatorColor} />}
            <SText
                sx={{
                    ...textStyle,
                    letterSpacing: '0.02em',
                    fontWeight: 'semiBold',
                    fontSize: 3,
                    fontFamily: 'default',
                    lineHeight: '40px',
                    ...textStyleProp,
                }}
            >
                {!loading && children}
            </SText>
        </Button>
    )
}

export default SButton
